<template>
    <div>
        <div>
            <p>
                <b> {{ $t('ACADEMICO.CURSO') }}: </b>
                <span>
                    {{ items[0]?.curso.descricao ?? '-' }}
                </span>
            </p>
            <p>
                <b> {{ $t('ACADEMICO.TURMA') }}: </b>
                <span>
                    {{ items[0]?.turma.descricao ?? '-' }}
                </span>
            </p>
        </div>

        <b-table
            :fields="fields"
            :items="items"
            :empty-text="$t('GERAL.NENHUM_REGISTRO')"
            head-variant="light"
            stacked="md"
            show-empty
            responsive
            striped
            hover
        />
    </div>
</template>

<script>
// helpers & utils
import datas from '@/common/utils/datas';

export default {
    props: {
        items: { type: Array, default: Array },
    },
    data() {
        return {
            fields: [
                {
                    key: 'avaliacao.dataAvaliacao',
                    label: this.$t('ACADEMICO.DATA'),
                    sortable: true,
                    formatter: (value) => {
                        return datas.formatDateToShow(value);
                    },
                },
                {
                    key: 'avaliacao.descricao',
                    label: this.$t('ACADEMICO.DESCRICAO'),
                    sortable: true,
                },
                {
                    key: 'valorAvaliacao',
                    label: this.$t('ACADEMICO.NOTA'),
                    sortable: true,
                },
                {
                    key: 'avaliacao.tipoAvaliacao.Descricao',
                    label: this.$t('ACADEMICO.TIPO_AVALIACAO'),
                    sortable: true,
                    formatter: (value) => {
                        return value ?? '-';
                    },
                },
                {
                    key: 'fezRecuperacao',
                    label: this.$t('ACADEMICO.RECUPERACAO'),
                    sortable: true,
                    formatter: (value) => (value ? 'Sim' : 'Não'),
                },
                {
                    key: 'avaliacao.dataRecuperacao',
                    label: this.$t('ACADEMICO.DATA_RECUPERACAO'),
                    sortable: true,
                    formatter: (value) =>
                        value ? datas.formatDateToShow(value) : '-',
                },
                {
                    key: 'avaliacao.conteudoProgramatico',
                    label: this.$t('ACADEMICO.CONTEUDO'),
                    sortable: true,
                    formatter: (value) => {
                        return value ?? '-';
                    },
                },
            ],
        };
    },
};
</script>

<style></style>
