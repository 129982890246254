<template>
    <div>
        <b-row>
            <b-col md="3" v-if="opcoes?.alunosVinculados?.length > 0">
                <input-select-search
                    ref="matriculaId"
                    :options="opcoes.alunosVinculados"
                    :label="$t('ACADEMICO.ALUNO')"
                    v-model="filtro.matriculaId"
                    required0
                />
            </b-col>
            <b-col md="3">
                <input-select-search
                    ref="anoLetivoId"
                    :label="$t(`ACADEMICO.ANOS`)"
                    :options="opcoes.anosLetivos"
                    v-model="filtro.anoLetivoId"
                    :placeholder="$t('GERAL.DIGITE_OU_PROCURE')"
                    required
                />
            </b-col>
            <b-col md="3">
                <input-select-search
                    ref="bimestreId"
                    :label="$t(`ACADEMICO.BIMESTRE`)"
                    :options="opcoes.bimestres"
                    :placeholder="$t('GERAL.DIGITE_OU_PROCURE')"
                    v-model="filtro.segmentacaoId"
                    :isDisabled="camposDesabilitados"
                    required
                />
            </b-col>
            <b-col md="3">
                <input-select-search
                    ref="disciplinaId"
                    :label="$t(`ACADEMICO.DISCIPLINA`)"
                    :placeholder="$t('GERAL.DIGITE_OU_PROCURE')"
                    :options="opcoes.disciplinas"
                    v-model="filtro.disciplinaId"
                    :isDisabled="camposDesabilitados"
                    required
                />
            </b-col>
        </b-row>

        <b-row>
            <b-col md="auto">
                <b-button class="w-100 mb-3" variant="primary" @click="filtrar">
                    {{ $t('ACADEMICO.OBTER_NOTAS_PARCIAIS') }}
                </b-button>
            </b-col>
            <b-col md="auto">
                <b-button
                    class="w-100 mb-3"
                    variant="secondary"
                    @click="limparFiltro"
                >
                    {{ $t('GERAL.LIMPAR') }}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';
import JwtService from '@/common/utils/jwt';

// Components:
import { InputSelectSearch } from '@/components/inputs';
import ContratoService from '@/common/services/contratos/contratos.service';

// Services
import SegmentacaoService from '@/common/services/segmentacao/segmentacao.service.js';
import DisciplinasService from '@/common/services/academico/disciplinas.service.js';

export default {
    components: {
        InputSelectSearch,
    },
    data() {
        return {
            opcoes: {
                alunosVinculados: [],
                anosLetivos: [],
                bimestres: [],
                disciplinas: [],
            },
            filtro: {
                matriculaId: null,
                anoLetivoId: null,
                segmentacaoId: null,
                disciplinaId: null,
            },
            camposDesabilitados: true,
        };
    },
    watch: {
        'filtro.matriculaId': {
            handler(value) {
                if (value) {
                    this.getAnosLetivosDoContrato();
                    this.filtro = {
                        ...this.filtro,
                        anoLetivoId: null,
                        segmentacaoId: null,
                        disciplinaId: null,
                    };
                }
            },
            immediate: true,
        },
        'filtro.anoLetivoId': {
            handler(value) {
                if (value) {
                    this.camposDesabilitados = false;
                    this.getBimestres();
                    this.getDisciplinas();
                    this.filtro.segmentacaoId = '';
                } else {
                    this.camposDesabilitados = true;
                }
            },
            immediate: true,
        },
        'opcoes.alunosVinculados': {
            handler(value) {
                if (value?.length > 0) {
                    this.filtro.matriculaId = null;
                    return;
                }
                this.filtro.matriculaId = JwtService.buscarMatricula();
            },
            immediate: true,
        },
    },
    mounted() {
        this.$emit('refs', this.$refs);
        this.getAlunosVinculadosResponsavel();
    },
    methods: {
        getAlunosVinculadosResponsavel() {
            this.opcoes.alunosVinculados =
                JwtService.buscarAlunosVinculados().reduce(
                    (alunosSemDuplicatas, aluno) => {
                        const existe = alunosSemDuplicatas.some(
                            (a) => a.value === aluno.matriculaId
                        );
                        if (!existe) {
                            alunosSemDuplicatas.push({
                                text: aluno.nome,
                                value: aluno.matriculaId,
                            });
                        }
                        return alunosSemDuplicatas;
                    },
                    []
                );
        },
        getAnosLetivosDoContrato() {
            if (!this.filtro?.matriculaId) return;
            this.$store.dispatch(START_LOADING);
            ContratoService.buscarPorMatricula(this.filtro.matriculaId, 1000, 1)
                .then(({ data }) => {
                    data = data.itens
                        .reduce((anosLetivosUnicos, contratoAtual) => {
                            if (
                                !anosLetivosUnicos.some(
                                    (ano) =>
                                        ano.value === contratoAtual.anoLetivoId
                                )
                            ) {
                                anosLetivosUnicos.push({
                                    value: contratoAtual.anoLetivoId,
                                    text: contratoAtual.anoLetivoDescricao.toString(),
                                });
                            }
                            return anosLetivosUnicos;
                        }, [])
                        .sort((a, b) => a.text - b.text);

                    this.opcoes.anosLetivos =
                        data.length > 0
                            ? data
                            : [
                                  {
                                      text: this.$t(
                                          'ACADEMICO.NENHUM_ANO_LETIVO'
                                      ),
                                      value: null,
                                  },
                              ];
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => this.$store.dispatch(STOP_LOADING));
        },
        getBimestres() {
            this.$store.dispatch(START_LOADING);
            SegmentacaoService.pegarPorAnoLetivoId(this.filtro.anoLetivoId)
                .then(({ data }) => {
                    data = data.map((seg) => ({
                        text: seg.descricao,
                        value: seg.id,
                    }));

                    data.sort((a, b) => a.text.localeCompare(b.text));

                    this.opcoes.bimestres =
                        data.length > 0
                            ? data
                            : [
                                  {
                                      text: this.$t(
                                          'ACADEMICO.NENHUMA_SEGMENTACAO'
                                      ),
                                      value: null,
                                  },
                              ];
                })
                .catch(() => {
                    this.filtro.bimestreId = null;
                    this.opcoes.bimestres = [
                        {
                            text: this.$t('ACADEMICO.NENHUMA_SEGMENTACAO'),
                            value: null,
                        },
                    ];
                })
                .finally(() => this.$store.dispatch(STOP_LOADING));
        },
        getDisciplinas() {
            this.$store.dispatch(START_LOADING);
            DisciplinasService.buscarDisciplinasPorAnoLetivoIdSemDocente(
                this.filtro.anoLetivoId
            )
                .then(({ data }) => {
                    data = data.map((row) => ({
                        ...row,
                        text: row.descricao,
                        value: row.id,
                    }));

                    data.sort((a, b) => a.text.localeCompare(b.text));

                    this.opcoes.disciplinas =
                        data.length > 0
                            ? data
                            : [
                                  {
                                      text: this.$t(
                                          'ACADEMICO.NENHUMA_DISCIPLINA'
                                      ),
                                      value: null,
                                  },
                              ];
                })
                .catch(() => {
                    this.filtro.disciplinaId = null;
                    this.opcoes.disciplinas = [
                        {
                            text: this.$t('ACADEMICO.NENHUMA_DISCIPLINA'),
                            value: null,
                        },
                    ];
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },
        // FUNÇÕES DO FILTRO:
        filtrar() {
            if (!this.validarFiltro()) return;
            this.$emit('filtrar', this.filtro);
        },

        validarFiltro() {
            return helpers.validarFormulario(this.$refs);
        },

        limparFiltro() {
            this.filtro =
                this.opcoes.alunosVinculados.length > 0
                    ? {
                          matriculaId: null,
                          anoLetivoId: null,
                          segmentacaoId: null,
                          disciplinaId: null,
                      }
                    : {
                          ...this.filtro,
                          anoLetivoId: null,
                          segmentacaoId: null,
                          disciplinaId: null,
                      };
            this.$emit('limpar-filtro');
        },
    },
};
</script>
