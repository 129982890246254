import ApiService from "@/common/api/api.service";

let apiBasePath = "Nota";
const NotasService = {
    async buscarNotasPorFiltro(form) {
        let { data } = await ApiService.post(`${apiBasePath}/obter-notas-parciais`, form);
        return data;
    }
};

export default NotasService;