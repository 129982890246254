<template>
    <div>
        <titulo-notas-parciais />
        <filtro-notas-parciais
            @filtrar="getNotasParciais"
            @limpar-filtro="limpar"
        />
        <tabela-notas-parciais :items="items" />

        <div v-if="aviso" class="text-center text-danger my-4">
            <span>{{ aviso }}</span>
        </div>
    </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// services
import NotasService from '@/common/services/academico/notas.service';

// Components:
import FiltroNotasParciais from './components/FiltroNotasParciais';
import TituloNotasParciais from './components/TituloNotasParciais';
import TabelaNotasParciais from './components/TabelaNotasParciais';

export default {
    components: {
        FiltroNotasParciais,
        TituloNotasParciais,
        TabelaNotasParciais,
    },
    data() {
        return {
            items: [],
            aviso: '',
        };
    },
    methods: {
        getNotasParciais(filtro) {
            filtro = { ...filtro };
            this.$store.dispatch(START_LOADING);
            NotasService.buscarNotasPorFiltro(filtro)
                .then(({ data }) => {
                    this.items = data;
                    if (data.length > 0) {
                        this.aviso = '';
                    } else {
                        this.aviso = this.$t('ACADEMICO.SEN_NOTAS_PARCIAIS');
                    }
                })
                .catch((err) => {
                    this.aviso = '';
                    this.items = [];
                    mensagem.showErrors(err);
                })
                .finally(() => this.$store.dispatch(STOP_LOADING));
        },
        limpar() {
            this.items = [];
            this.aviso = '';
        },
    },
};
</script>
